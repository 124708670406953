import React from "react"
import { SpinComponent, SpinComponentCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRulerHorizontal } from "@fortawesome/free-solid-svg-icons"

export default {
  id: "antd-spin",
  block: {
    category: "Ant Design",
    icon: <FontAwesomeIcon size="3x" icon={faRulerHorizontal} />,
    label: "Spin",
  },
  props: [],
  component: SpinComponent,
  canvas: SpinComponentCanvas,
  components: [],
  styles: {
    position: "relative",
    paddingTop: 20,
    paddingBottom: 20,
  },
}
