import React from "react"
import { FormRadio, FormRadioCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons"

export default {
  id: "from-radio",
  block: {
    category: "Form",
    icon: <FontAwesomeIcon size="3x" icon={faCheckCircle} />,
    label: "Radio",
  },
  props: [
    {
      name: "name",
      label: "name",
      type: "text",
      default: "input-name",
    },
    {
      name: "label",
      label: "label",
      type: "text",
      default: "Label group name",
    },
    {
      name: "hideLabel",
      label: "Hide Label",
      type: "boolean",
      default: false,
    },
    {
      name: "labels",
      label: "Labels",
      type: "text",
      default: "Label 1, Label 2",
    },
    {
      name: "values",
      label: "Values",
      type: "text",
      default: "value1, value2",
    },
    {
      name: "fluid",
      label: "Matrix Layout",
      type: "boolean",
      default: false,
    },
    {
      name: "grid",
      label: "Grid Layout",
      type: "boolean",
      default: false,
    },
    {
      name: "inline",
      label: "Inline Layout",
      type: "boolean",
      default: false,
    },
    {
      name: "labelsOnly",
      label: "Labels Only",
      type: "boolean",
      default: false,
    },
  ],
  component: FormRadio,
  canvas: FormRadioCanvas,
  components: [],
  styles: {
    position: "relative",
  },
}
