import React, { useContext, useState, useEffect } from "react"

import { EditorContext } from "../../Contexts/EditorContext"

import { renderTopLevelComponent } from "../../scripts/RenderReact"
import { getSections } from "../../scripts/server/pageContent"
import { SectionsContext } from "../../Contexts/SectionContext"

export const SectionComponent = ({ stateProps, htmlProps }) => {
  const [sections] = useContext(SectionsContext)
  const section = sections.find(({ id }) => id === stateProps.section)

  if (!section) return null

  return (
    <div {...htmlProps}>
      {renderTopLevelComponent(
        section.data.components.iv,
        section.data.slug.iv
      )}
    </div>
  )
}
SectionComponent.displayName = "SectionComponent"

export const SectionComponentCanvas = ({ stateProps, htmlProps }) => {
  const [state, dispatch] = useContext(EditorContext)
  const [sections, setSections] = useState([])

  useEffect(() => {
    const asyncFct = async () => {
      setSections(await getSections())
    }
    asyncFct()
  }, [])

  const section = sections.find(({ id }) => id === stateProps.section)

  if (!section)
    return (
      <div className="pb-p-4" {...htmlProps}>
        No section found with id {stateProps.section}
      </div>
    )

  const newProps = {
    ...htmlProps,
    onClick: () => {
      dispatch(["SELECT_COMPONENT", stateProps.id])
    },
  }

  stateProps.customProps.isSectionComponentChild = true

  // console.log("SECTION COMP", stateProps)

  return (
    <div {...newProps}>
      {renderTopLevelComponent(
        section.data.components.iv,
        section.data.slug.iv,
        null,
        true,
        {},
        stateProps.customProps
      )}
    </div>
  )
}
SectionComponentCanvas.displayName = "SectionComponentCanvas"
