import React from "react"
import ReactDOM from "react-dom"

import Text from "./Text"
import Section from "./Section"
import Container from "./Container"
import RichText from "./RichText"
import TextHeader from "./TextHeader"
import Link from "./Link"
import Div from "./Div"
import Image from "./Image"

import SemanticUIButton from "./SemanticUI/Button"
import SectionComponent from "./SectionComponent"
import DynamicText from "./DynamicText"
import Form from "./Form"
import Radio from "./FormInputs/Radio"
import ShowIf from "./ShowIf"
import Checkbox from "./FormInputs/Checkbox"
import Input from "./FormInputs/Input"
import Reset from "./FormInputs/Reset"
import Space from "./AntDegisn/Space"
import Repeatable from "./Repeatable"
import Icon from "./Icon"
import SVG from "./SVG"
import LanguageSwitch from "./LanguageSwitch"
import Carousel from "./AntDegisn/Carousel"
import Spin from "./AntDegisn/Spin"

const components = [
  Text,
  Section,
  Container,
  RichText,
  TextHeader,
  Link,
  SemanticUIButton,
  Div,
  Image,
  SectionComponent,
  DynamicText,
  Form,
  Radio,
  ShowIf,
  Checkbox,
  Input,
  Reset,
  Space,
  Repeatable,
  Icon,
  SVG,
  LanguageSwitch,
  Carousel,
  Spin,
]

const componentsMap = new Map(components.map(c => [c.id, c]))

export const getComponentsMap = () => {
  return componentsMap
}

export const getComponents = components
