import React from "react"
import { Container, ContainerCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBorderStyle } from "@fortawesome/free-solid-svg-icons"

export default {
  id: "container",
  block: {
    category: "Layout",
    icon: <FontAwesomeIcon size="3x" icon={faBorderStyle} />,
    label: "Container",
  },
  props: [],
  component: Container,
  canvas: ContainerCanvas,
  components: [],
  styles: {
    position: "relative",
    maxWidth: "1140px",
    marginTop: 0,
    marginBottom: 0,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
}
