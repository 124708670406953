import React from "react"
import { Form, FormCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"

export default {
  id: "form",
  block: {
    category: "Form",
    icon: <FontAwesomeIcon size="3x" icon={faEnvelope} />,
    label: "Form",
  },
  props: [
    {
      name: "submitFunction",
      label: "Submit Function",
      type: "text",
      default: "",
    },
    {
      name: "saveCache",
      label: "Save in the local cache",
      type: "boolean",
      default: false,
    },
  ],
  component: Form,
  canvas: FormCanvas,
  components: [],
  styles: {
    position: "relative",
    paddingTop: 20,
    paddingBottom: 20,
  },
}
