import React from "react"
import { css } from "emotion"
import get from "lodash/get"

import BlockSelector from "../../templates/components/BlockSelector"

export const Repeatable = ({ stateProps, htmlProps, children }) => {
  const list = stateProps.context
  console.log("REPEATABLE", stateProps, list)

  if (!Array.isArray(list)) return null

  return (
    <div {...htmlProps}>
      {list.map((item, index) => {
        return stateProps.childrenWithProps({ item })
      })}
    </div>
  )
}
Repeatable.displayName = "Repeatable"

export const RepeatableCanvas = ({ stateProps, htmlProps, children }) => {
  const itemBorder = css`
    border: 1px dashed #108ee9;
  `

  return (
    <div css={itemBorder} {...htmlProps}>
      {children}

      {!children && <BlockSelector selected={stateProps.id} />}
    </div>
  )
}
RepeatableCanvas.displayName = "RepeatableCanvas"
