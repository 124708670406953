/* eslint-disable no-case-declarations */
import React, { useReducer, useEffect, useState } from "react"
import randomstring from "randomstring"
import { getPageOrSectionID } from "../scripts/server/pageContent"

export const SectionsContext = React.createContext()

const reducer = (state, [type, payload]) => {
  switch (type) {
    case "SET_INITIAL_STATE":
      return {
        ...state,
        ...payload,
      }
    case "SET_COMPONENTS":
      return payload

    default:
      return state
  }
}

export const SectionsContextProvider = props => {
  const [state, dispatch] = useReducer(
    reducer,
    props.initialState ? props.initialState : []
  )
  const [oldState, setOldState] = useState(props.initialState)

  useEffect(() => {
    if (JSON.stringify(oldState) !== JSON.stringify(props.initialState)) {
      dispatch(["SET_INITIAL_STATE", props.initialState])
      setOldState(props.initialState)
    }
  }, [props.initialState])

  return (
    <SectionsContext.Provider value={[state, dispatch]}>
      {" "}
      {props.children}{" "}
    </SectionsContext.Provider>
  )
}
