import React, { useState, useEffect } from "react"
import { SectionComponent, SectionComponentCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faColumns } from "@fortawesome/free-solid-svg-icons"
import { Select, Form, Button } from "semantic-ui-react"
import { getSections } from "../../scripts/server/pageContent"

const SectionSelect = ({ onChange, value, label }) => {
  const [sections, setSections] = useState([])

  useEffect(() => {
    const asyncFct = async () => {
      setSections(await getSections())
    }
    asyncFct()
  }, [])

  const options = sections.map(s => ({
    key: s.id,
    value: s.id,
    text: s.data.name.iv,
  }))

  console.log("OPTIONS", options)

  const updateSelect = (_elem, { name, value }) => {
    onChange(value)
  }

  return (
    <div>
      <Form.Field
        control={Select}
        label={label}
        value={value}
        options={options}
        onChange={updateSelect}
      />

      {value && (
        <div>
          <Button as="a" fluid href={`/admin/editor?section=${value}`}>
            Edit this section
          </Button>
        </div>
      )}
    </div>
  )
}

export default {
  id: "section-component",
  block: {
    category: "Basic",
    icon: <FontAwesomeIcon size="3x" icon={faColumns} />,
    label: "Section Component",
  },
  props: [
    {
      name: "section",
      label: "Section",
      type: "custom",
      default: null,
      input: props => <SectionSelect {...props} />,
    },
  ],
  component: SectionComponent,
  canvas: SectionComponentCanvas,
  components: [],
  styles: {},
}
