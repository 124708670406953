import React from "react"
import BlockSelector from "../../templates/components/BlockSelector"

export const Form = ({ stateProps, htmlProps, children }) => {
  return <form {...htmlProps}>{children}</form>
}
Form.displayName = "Form"

export const FormCanvas = ({ stateProps, htmlProps, children }) => {
  return (
    <form
      onSubmit={e => {
        e.preventDefault()
      }}
      {...htmlProps}
    >
      {children}

      {!children && <BlockSelector selected={stateProps.id} />}
    </form>
  )
}
FormCanvas.displayName = "FormCanvas"
