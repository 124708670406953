import React from "react"
import markdownit from "markdown-it"
import underline from "markdown-it-plugin-underline"

import { EditableText } from "../../templates/components/EdtitableText"

const Renderer = markdownit().use(underline)

export const DynamicText = ({ stateProps, htmlProps }) => {
  console.log("TEXT", stateProps.text, typeof stateProps.text)
  return (
    <div {...htmlProps}>
      {stateProps.text && !stateProps.text.startsWith("undefined") ? (
        <div
          dangerouslySetInnerHTML={{ __html: Renderer.render(stateProps.text) }}
        />
      ) : null}
    </div>
  )
}
DynamicText.displayName = "DynamicText"

export const DynamicTextCanvas = ({ stateProps, htmlProps }) => {
  const [state] = stateProps.pageContext

  if (state.selected !== stateProps.id)
    return <DynamicText stateProps={stateProps} htmlProps={htmlProps} />

  return (
    <div {...htmlProps}>
      <EditableText text={stateProps.text} rawText={stateProps.textRaw} />
    </div>
  )
}
DynamicTextCanvas.displayName = "DynamicTextCanvas"
