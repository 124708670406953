import React from "react"
import { Input, InputCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWindowMinimize } from "@fortawesome/free-regular-svg-icons"

export default {
  id: "from-input",
  block: {
    category: "Form",
    icon: <FontAwesomeIcon size="3x" icon={faWindowMinimize} />,
    label: "Input",
  },
  props: [
    {
      name: "name",
      label: "name",
      type: "text",
      default: "input-name",
    },
    {
      name: "label",
      label: "Label",
      type: "text",
      default: "Label 1",
    },
    {
      name: "type",
      label: "Type",
      type: "select",
      values: ["text", "textarea", "number", "date", "time", "datetime"],
      default: "text",
    },
  ],
  component: Input,
  canvas: InputCanvas,
  components: [],
  styles: {
    position: "relative",
  },
}
