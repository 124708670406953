import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

export const LanguageSwitch = ({ stateProps, htmlProps }) => {
  const [pageState] = stateProps.pageContext

  console.log("LANG SWITCH", pageState)

  let slug =
    pageState.slug ||
    (typeof window !== "undefined" && window.location.pathname) ||
    "/"
  const lang = pageState.lang
  let to = "/"

  console.log("SLUG", slug, lang)

  const map = {
    "/": "/en",
    index: "/en",
    "fr/quest-ce-que-le-refer-o-scope": "/en/what-is-the-refer-o-scope",
    "fr/a-propos-de-la-psychose": "/en/what-you-should-know-about-psychosis",
    "fr/conditions-dutilisation": "/en/terms-of-use",
    en: "/",
    "en/what-is-the-refer-o-scope": "/fr/quest-ce-que-le-refer-o-scope",
    "en/what-you-should-know-about-psychosis": "/fr/a-propos-de-la-psychose",
    "en/terms-of-use": "/fr/conditions-dutilisation",
  }

  if (map[slug]) to = map[slug]
  else if (map[slug.slice(1)]) to = map[slug.slice(1)]
  else
    to = slug.replace(
      `/${lang === "en-ca" ? "en" : "fr"}`,
      `/${lang === "en-ca" ? "fr" : "en"}`
    )

  console.log("TO", to)

  return (
    <Link {...htmlProps} to={to}>
      {lang === "en-ca" ? "Français" : "English"}
    </Link>
  )
}

export const LanguageSwitchCanvas = ({ stateProps, htmlProps }) => {
  const [pageState] = stateProps.pageContext

  console.log("LANG SWITCH", pageState.slug)
  const parts = pageState.slug.split("/")
  const lang = parts[1]

  let style = css`
    padding: 5px 0;
    border: 1px dashed orange;
  `

  return (
    <div css={style} {...htmlProps}>
      <a>{lang === "fr" ? "English" : "Français"}</a>
    </div>
  )
}
