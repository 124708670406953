import React, { useReducer } from "react"

export const EditorContext = React.createContext()

const initialState = {
  selected: null,
  dragging: null,
  screenSize: "desktop",
  page: {
    id: "",
    data: {
      name: {
        iv: "",
      },
    },
  },
  lang: "iv",
}

const reducer = (state, [type, payload]) => {
  switch (type) {
    case "SET_INITIAL_STATE":
      return {
        ...state,
        ...payload,
      }
    case "SELECT_COMPONENT":
      return {
        ...state,
        selected: payload,
      }
    case "SET_DRAGGING":
      return {
        ...state,
        dragging: payload,
      }

    case "SET_LANG":
      return {
        ...state,
        lang: payload,
      }

    case "SET_SCREEN_SIZE":
      return {
        ...state,
        screenSize: payload,
      }

    default:
    /* throw new Error("Undefined Action"); */
  }
}

export const EditorContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <EditorContext.Provider value={[state, dispatch]}>
      {" "}
      {props.children}{" "}
    </EditorContext.Provider>
  )
}
