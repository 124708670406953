import React from "react"
import classnames from "classnames"
import { inputValue, updateFormField } from "../shared"

export const Input = ({ stateProps, htmlProps, children }) => {
  const [state, dispatch] = stateProps.pageContext

  const update = e =>
    updateFormField(
      stateProps.form.id,
      stateProps.name,
      e.target.value,
      dispatch,
      stateProps.label,
      e.target.value
    )
  const value = inputValue(stateProps.form.id, stateProps.name, state)

  const Input = stateProps.type === "textarea" ? "textarea" : "input"

  return (
    <div
      {...htmlProps}
      className={classnames(htmlProps.className, "pb-field pb-field-group")}
    >
      {stateProps.label && (
        <label htmlFor={stateProps.name}>{stateProps.label}</label>
      )}{" "}
      <br />
      <Input
        type={stateProps.type}
        id={stateProps.name}
        name={stateProps.name}
        value={value}
        onChange={update}
      />
    </div>
  )
}
Input.displayName = "Input"

export const InputCanvas = Input
InputCanvas.displayName = "InputCanvas"
