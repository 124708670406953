import React from "react"
import { Image, ImageCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faImage } from "@fortawesome/free-solid-svg-icons"

export default {
  id: "image",
  block: {
    category: "Basic",
    icon: <FontAwesomeIcon size="3x" icon={faImage} />,
    label: "Image",
  },
  props: [
    {
      name: "src",
      label: "URL",
      type: "text",
      default: "#",
    },
    {
      name: "alt",
      label: "atl tag",
      type: "text",
      default: "Describe the image here",
    },
  ],
  component: Image,
  canvas: ImageCanvas,
  components: [],
  styles: {
    width: "100%",
    height: "auto",
    maxWidth: "100%",
    marginTop: 0,
    marginBottom: 0,
  },
}
