import React, { useContext, useState } from "react"
import { css, ClassNames } from "@emotion/core"
import { Button, Modal } from "semantic-ui-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTimes,
  faTrashAlt,
  faPlus,
  faClone,
} from "@fortawesome/free-solid-svg-icons"

import { getComponentsMap } from "../components/index"
import BlockDropzone from "../templates/components/BlockDropzone"
import { PageContext } from "../Contexts/PageContext"
import { compareString, addPageContext } from "./addPageContext"
import { contextReference, functionReference } from "./contextReference"
import { getAppliedStyles } from "./stylesUtils"
import { EditorContext } from "../Contexts/EditorContext"
import { hasParentType } from "./componentsUtils"
import { getTranslated } from "./langUtils"

let defaultState = {}

const ComponentWrapper = ({
  component,
  components,
  renderProps,
  componentProps,
  children,
}) => {
  const editorContext = useContext(EditorContext)
  const state = typeof editorContext !== "undefined" ? editorContext[0] : {}
  const pageContext = useContext(PageContext)
  const [pageState, pageDispatch] = pageContext

  let ReactComponent = component

  const {
    initialRenderState,
    form,
    index,
    classNames,
    customProps,
    childrenWithProps,
    forCanvas,
    styles,
  } = renderProps
  let compProps = componentProps

  let stateProps = {
    index,
    initialRenderState,
    form,
    pageContext,
    customProps,
    childrenWithProps,
  }

  let htmlProps = {
    key: index,
  }

  const c = components[componentProps.id]
  const compDefMap = getComponentsMap()

  // console.log("RENDER DATA ID", components, compProps.id, hasParentType(components, 'section-component', compProps.id))
  if (!customProps.isSectionComponentChild) {
    htmlProps["data-sb-comp-id"] = compProps.id
  }

  let addActiveClass = false

  for (let key of Object.keys(compProps)) {
    if (c && c.type !== "wrapper") {
      const compDef = compDefMap.get(c.type)

      const propDef = compDef.props.find(({ name }) => name === key)
      const value = compProps[key]
      // console.log("LANG", state.lang, pageState, state.lang || pageState.lang)
      const translatedValue = getTranslated(
        compProps[key],
        state.lang || pageState.lang
      )

      stateProps[key + "Raw"] = value

      if (propDef && propDef.type === "text" && !propDef.dynamic) {
        propDef.htmlProp
          ? (htmlProps[key] = translatedValue)
          : (stateProps[key] = translatedValue)
      } else if (propDef && propDef.htmlProp) {
        if (propDef.type === "boolean" && typeof compProps[key] === "string")
          htmlProps[key] = compareString(translatedValue, pageState)
        else htmlProps[key] = value
      } else if (propDef && propDef.dynamic && !forCanvas) {
        stateProps[key] = addPageContext(translatedValue, {
          ...pageState,
          ...customProps,
        })
      } else if (propDef && propDef.dynamic && forCanvas) {
        stateProps[key] = translatedValue
      } else if (
        propDef &&
        propDef.type === "context-reference" &&
        !forCanvas
      ) {
        stateProps[key] = contextReference(translatedValue, {
          ...pageState,
          ...customProps,
        })
      } else if (key === "onClick" && !forCanvas) {
        htmlProps[key] = functionReference(value, {
          ...pageState,
          ...customProps,
        })
      } else if (key === "activeIf" && !forCanvas) {
        addActiveClass = compareString(translatedValue, pageState)
      } else {
        stateProps[key] = value
      }
    }
  }

  let cssStyles = getAppliedStyles(forCanvas, styles, state.screenSize)
  htmlProps.className = classNames
    .filter(
      name =>
        !name.startsWith("active_") ||
        (name.startsWith("active_") && addActiveClass)
    )
    .join(" ")

  // console.log("CSS STYLES", htmlProps.className)

  if (ReactComponent === "div")
    return (
      <ClassNames>
        {({ css, cx }) => (
          <div
            key={index}
            {...htmlProps}
            className={`${css(cssStyles)} ${htmlProps.className}`}
          >
            {children}
          </div>
        )}
      </ClassNames>
    )

  return (
    <ClassNames>
      {({ css, cx }) => (
        <ReactComponent
          key={index}
          stateProps={stateProps}
          htmlProps={{
            ...htmlProps,
            className: `${css(cssStyles)} ${htmlProps.className}`,
          }}
        >
          {children}
        </ReactComponent>
      )}
    </ClassNames>
  )
}

const ComponentWrapperMemo = React.memo(ComponentWrapper)

export const renderComponent = (
  id,
  components,
  index = 0,
  selected = null,
  forCanvas = false,
  customProps = {}
) => {
  if (!components) return null

  const c = components[id]

  if (!c) return null

  let ReactComponent = "div"

  // console.log("CUSTOM PROPS", c.type, customProps)

  if (getComponentsMap().has(c.type))
    ReactComponent = forCanvas
      ? getComponentsMap().get(c.type).canvas
      : getComponentsMap().get(c.type).component

  if (c.type === "form" && !customProps)
    customProps = { ...customProps, formId: id }
  else if (c.type === "form" && customProps) customProps.formId = id

  let children = null
  if (c.components && c.components.length) {
    children = renderComponents(
      c.components,
      components,
      selected,
      forCanvas,
      customProps
    )
  }

  let classNames = [...c.classNames.map(c => c.replace(".", ""))]

  let renderProps = {
    initialRenderState: defaultState,
    form: { id: customProps && customProps.formId },
    index: index,
    classNames: classNames,
    customProps: { ...customProps },
    forCanvas,
    styles: c.styles,
  }

  if (
    c.components &&
    c.components.length &&
    getComponentsMap().get(c.type) &&
    getComponentsMap().get(c.type).customProps &&
    !forCanvas
  ) {
    renderProps.childrenWithProps = props =>
      renderComponents(c.components, components, selected, forCanvas, {
        ...props,
        ...customProps,
      })
  }

  if (!forCanvas)
    return (
      <ComponentWrapperMemo
        key={index}
        component={ReactComponent}
        components={components}
        renderProps={renderProps}
        componentProps={c.props}
      >
        {children}
      </ComponentWrapperMemo>
    )

  let border = css({
    border: id === selected ? "1px dashed #1f7fc8" : undefined,
  })
  if (id === selected) classNames += ` ${border}`

  return (
    <>
      {index === 0 && !customProps.isSectionComponentChild && (
        <BlockDropzone id={id} index={0} />
      )}
      <ComponentWrapperMemo
        key={index}
        component={ReactComponent}
        components={components}
        renderProps={renderProps}
        componentProps={c.props}
      >
        {children}
        {!children && <BlockDropzone parentId={id} index={0} />}
      </ComponentWrapperMemo>
      {!customProps.isSectionComponentChild && (
        <BlockDropzone id={id} index={index + 1} />
      )}
    </>
  )
}

export const renderComponents = (
  comps,
  components,
  selected = null,
  forCanvas = false,
  customProps = {}
) => {
  return comps.map((c, index) =>
    renderComponent(c, components, index, selected, forCanvas, customProps)
  )
}

export const renderTopLevelComponent = (
  components,
  id,
  selected = null,
  forCanvas = false,
  newState = {},
  customProps = {}
) => {
  defaultState = newState
  // console.log("FOR CANVANS", forCanvas)
  return renderComponent(id, components, 0, selected, forCanvas, customProps)
}
