import React, { useContext } from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import BlockSelector from "../../templates/components/BlockSelector"
import { PageContext } from "../../Contexts/PageContext"
import { addPageContext } from "../../scripts/addPageContext"

function linkTypeSelector(stateProps, htmlProps, children, pageState) {
  switch (stateProps.linkType) {
    case "gatsby":
      return (
        <Link {...htmlProps} to={addPageContext(stateProps.to, pageState)}>
          {children}
        </Link>
      )
    case "html":
      let htmlPropsCopy = { ...htmlProps }
      if (stateProps.download && stateProps.download.length > 0)
        htmlPropsCopy.download = stateProps.download
      return (
        <a
          {...htmlPropsCopy}
          href={addPageContext(stateProps.href, pageState)}
          target={stateProps.target}
        >
          {children}
        </a>
      )
    default:
      return <a href="#">Error in link</a>
  }
}

export const LinkBlock = ({ stateProps, htmlProps, children }) => {
  const [pageState] = useContext(PageContext)

  return linkTypeSelector(stateProps, htmlProps, children, pageState)
}
LinkBlock.displayName = "Link"

export const LinkCanvas = ({ stateProps, htmlProps, children }) => {
  const noChild = <BlockSelector selected={stateProps.id} />

  let style = css`
    padding: 5px 0;
    border: 1px dashed orange;
  `

  return (
    <div css={style} {...htmlProps}>
      <a>{children ? children : noChild}</a>
    </div>
  )
}
LinkCanvas.displayName = "LinkCanvas"
