import React from "react"
import { LinkBlock, LinkCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLink } from "@fortawesome/free-solid-svg-icons"

export default {
  id: "link",
  block: {
    category: "Basic",
    icon: <FontAwesomeIcon size="3x" icon={faLink} />,
    label: "Link",
  },
  props: [
    {
      name: "linkType",
      label: "Link Type",
      type: "select",
      default: "gatsby",
      values: ["gatsby", "html", "asset"],
    },
    {
      name: "to",
      label: "To",
      type: "text",
      default: "/",
      showIf: props => props.type === "gatsby",
    },
    {
      name: "href",
      label: "href",
      type: "text",
      default: "#",
      showIf: props => props.type === "html",
    },
    {
      name: "target",
      label: "target",
      type: "text",
      default: "_self",
      showIf: props => props.type === "html",
    },
    {
      name: "download",
      label: "Filename (Download)",
      type: "text",
      default: false,
      showIf: props => props.type === "html",
    },
  ],
  component: LinkBlock,
  canvas: LinkCanvas,
  components: [],
  styles: {},
}
