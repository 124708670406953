export const updateFormField = (
  formId,
  name,
  value,
  dispatch,
  label = "",
  textValue = ""
) => {
  console.log("UPDATE FORM", formId, name, value, label, textValue)

  dispatch([
    "SET_FORM_VALUE",
    {
      formId,
      name,
      value,
      label,
      textValue,
    },
  ])
}

export const inputValue = (formId, name, state) => {
  if (!state.forms || !state.forms[formId] || !state.forms[formId].values)
    return ""

  return state.forms[formId].values[name]
}

export const isChecked = (formId, name, state) => {
  if (!state.forms || !state.forms[formId] || !state.forms[formId].values)
    return false

  return state.forms[formId].values[name]
}

export const isSelected = (formId, name, value, state) => {
  if (!state.forms || !state.forms[formId] || !state.forms[formId].values)
    return false

  return state.forms[formId].values[name] == value
}
