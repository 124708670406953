import React from "react"
import { FormCheckbox, FormCheckboxCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons"

export default {
  id: "from-checkbox",
  block: {
    category: "Form",
    icon: <FontAwesomeIcon size="3x" icon={faCheckSquare} />,
    label: "Checkbox",
  },
  props: [
    {
      name: "name",
      label: "name",
      type: "text",
      default: "input-name",
    },
    {
      name: "label",
      label: "Label",
      type: "text",
      default: "Label 1",
    },
    {
      name: "value",
      label: "Value",
      type: "text",
      default: "value1",
    },
  ],
  component: FormCheckbox,
  canvas: FormCheckboxCanvas,
  components: [],
  styles: {
    position: "relative",
  },
}
