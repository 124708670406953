import React from "react"
import { DynamicText, DynamicTextCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileWord } from "@fortawesome/free-solid-svg-icons"
import { Label } from "semantic-ui-react"

export default {
  id: "dynamic-text",
  block: {
    category: "Dynamic",
    icon: <FontAwesomeIcon size="3x" icon={faFileWord} />,
    label: "Dynamic Text",
  },
  props: [
    {
      name: "text",
      label: "Text",
      type: "text",
      default: "This is the name of the current page: {name}",
      editable: false,
      dynamic: true,
    },
  ],
  component: DynamicText,
  canvas: DynamicTextCanvas,
  components: [],
  styles: {
    position: "relative",
  },
}
