import React from "react"
import { Text, TextCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFont } from "@fortawesome/free-solid-svg-icons"
import { Label } from "semantic-ui-react"

export default {
  id: "text",
  block: {
    category: "Basic",
    icon: <FontAwesomeIcon size="3x" icon={faFont} />,
    label: "Text",
  },
  props: [
    {
      name: "text",
      label: "Text",
      type: "text",
      default: "This is some editable text.",
      editable: false,
    },
  ],
  component: Text,
  canvas: TextCanvas,
  components: [],
  styles: {
    position: "relative",
  },
}
