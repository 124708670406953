import React from "react"

import BlockSelector from "../../templates/components/BlockSelector"

export const Section = ({ stateProps, htmlProps, children }) => {
  return <section {...htmlProps}>{children}</section>
}
Section.displayName = "Section"

export const SectionCanvas = ({ stateProps, htmlProps, children }) => {
  return (
    <section {...htmlProps}>
      {children}

      {!children && <BlockSelector selected={stateProps.id} />}
    </section>
  )
}
SectionCanvas.displayName = "SectionCanvas"
