import React from "react"

import BlockSelector from "../../templates/components/BlockSelector"

export const Container = ({ stateProps, htmlProps, children }) => {
  return <div {...htmlProps}>{children}</div>
}
Container.displayName = "Container"

export const ContainerCanvas = ({ stateProps, htmlProps, children }) => {
  return (
    <div {...htmlProps}>
      {children}

      {!children && <BlockSelector selected={stateProps.id} />}
    </div>
  )
}
ContainerCanvas.displayName = "ContainerCanvas"
