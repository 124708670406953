import React from "react"
import { Div, DivCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCube } from "@fortawesome/free-solid-svg-icons"

export default {
  id: "div",
  block: {
    category: "Basic",
    icon: <FontAwesomeIcon size="3x" icon={faCube} />,
    label: "Div",
  },
  props: [],
  component: Div,
  canvas: DivCanvas,
  components: [],
  styles: {
    position: "relative",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
}
