import React from "react"
import { ButtonBlock, ButtonCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquare } from "@fortawesome/free-solid-svg-icons"

export default {
  id: "semantic-button",
  block: {
    category: "Semantic UI",
    icon: <FontAwesomeIcon size="3x" icon={faSquare} />,
    label: "Button",
  },
  props: [
    {
      name: "content",
      label: "Content",
      desc: "Shorthand for primary content.",
      type: "text",
      default: "Button Label Text",
      htmlProp: true,
    },
    {
      name: "size",
      label: "Size",
      desc: "A button can have different sizes.",
      type: "select",
      values: [
        "mini",
        "tiny",
        "small",
        "medium",
        "large",
        "big",
        "huge",
        "massive",
      ],
      default: "medium",
      htmlProp: true,
    },
    {
      name: "color",
      label: "Button Color",
      desc: "A button can have different colors",
      type: "select",
      values: [
        "red",
        "orange",
        "yellow",
        "olive",
        "green",
        "teal",
        "blue",
        "violet",
        "purple",
        "pink",
        "brown",
        "grey",
        "black",
        "facebook",
        "google plus",
        "instagram",
        "linkedin",
        "twitter",
        "vk",
        "youtube",
      ],
      default: "grey",
      htmlProp: true,
    },
    {
      name: "basic",
      label: "Basic",
      desc: "A basic button is less pronounced.",
      type: "boolean",
      default: false,
      htmlProp: true,
    },
    {
      name: "circular",
      label: "Circular",
      desc: "A button can be circular.",
      type: "boolean",
      default: false,
      htmlProp: true,
    },
    {
      name: "compact",
      label: "Compact",
      desc: "A button can reduce its padding to fit into tighter spaces.",
      type: "boolean",
      default: false,
      htmlProp: true,
    },
    {
      name: "fluid",
      label: "Fluid",
      desc: "A button can take the width of its container.",
      type: "boolean",
      default: false,
      htmlProp: true,
    },
    {
      name: "inverted",
      label: "Inverted",
      desc: "A button can be formatted to appear on dark backgrounds.",
      type: "boolean",
      default: false,
      htmlProp: true,
    },
    {
      name: "primary",
      label: "Primary",
      desc: "A button can be formatted to show different levels of emphasis.",
      type: "boolean",
      default: false,
      htmlProp: true,
    },
    {
      name: "secondary",
      label: "Secondary",
      desc: "A button can be formatted to show different levels of emphasis.",
      type: "boolean",
      default: false,
      htmlProp: true,
    },
    {
      name: "disabled",
      label: "Disabled",
      type: "boolean",
      default: false,
      htmlProp: true,
    },
    {
      name: "type",
      label: "Type",
      desc: "The type of button",
      type: "select",
      values: ["button", "submit", "reset"],
      default: "button",
      htmlProp: true,
    },
  ],
  component: ButtonBlock,
  canvas: ButtonCanvas,
  components: [],
  styles: {
    position: "relative",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
  },
}
