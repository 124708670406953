import React, { useContext } from "react"
import { useDrop } from "react-dnd"
import { css, jsx } from "@emotion/core"
import { EditorContext } from "../../Contexts/EditorContext"

const BlockDropzone = ({ parentId, id, index }) => {
  const [state] = useContext(EditorContext)

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "block",
    drop: () => ({ parentId, id, index }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  if (!state.dragging || id === "wrapper") return null

  const isActive = canDrop && isOver

  const style = css`
    position: absolute;
    height: 30px;
    width: 100%;
    margin-top: -15px;
    z-index: 1000;
  `

  const targetStyle = css`
    position: relative;
    background-color: rgb(29, 161, 242);
    margin: 5px 0;
    height: 4px;
    width: 100%;
  `

  const childDropzone = css`
    border: 1px dashed #ddd;
    height: 80px;
    padding: 10px;
    font-size: 10px;
    text-align: center;
    margin-top: -20px;
  `

  return (
    <>
      <div ref={drop} css={style}></div>
      {isActive && <div css={targetStyle}></div>}
      {parentId && <div css={childDropzone}>Drop a first block here!</div>}
    </>
  )
}

export default BlockDropzone
