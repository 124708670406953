import React, { useContext } from "react"

import { Button } from "semantic-ui-react"

import { EditorContext } from "../../../Contexts/EditorContext"
import { compareString } from "../../../scripts/addPageContext"
import { PageContext } from "../../../Contexts/PageContext"

export const ButtonBlock = ({ stateProps, htmlProps, children }) => {
  const [pageState] = useContext(PageContext)

  let newProps = {
    ...htmlProps,
  }

  return <Button {...newProps} />
}
ButtonBlock.displayName = "ButtonBlock"

export const ButtonCanvas = ({ stateProps, htmlProps, children }) => {
  const [state, dispatch] = useContext(EditorContext)
  const [pageState] = useContext(PageContext)

  const newProps = {
    ...htmlProps,
    onClick: () => {
      dispatch(["SELECT_COMPONENT", stateProps.id])
    },
  }

  return <Button {...newProps} />
}
ButtonCanvas.displayName = "ButtonCanvas"
