export const getTranslated = (content, lang) => {
  // console.log("GET TRANSLATED", content, lang)
  if (content && typeof content === "object" && content[lang])
    return content[lang]
  else if (content && typeof content === "object" && content["iv"])
    return content["iv"]

  return content
}

export const setTranslated = (content, state, lang) => {
  console.log("SET TRANSLATION", content, state, lang)

  if (typeof state === "string" && typeof lang !== "undefined")
    return { iv: state, [lang]: content }

  return {
    ...state,
    iv: state.iv ? state.iv : content,
    [lang]: content,
  }
}
