import React, { useContext, useState, useEffect } from "react"
import Draft, { EditorState, RichUtils } from "draft-js"
import isSoftNewlineEvent from "draft-js/lib/isSoftNewlineEvent"
import { stateToMarkdown } from "draft-js-export-markdown"
import { stateFromMarkdown } from "draft-js-import-markdown"
import { Map } from "immutable"
import { css } from "@emotion/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBold,
  faItalic,
  faUnderline,
  faCode,
  faRemoveFormat,
  faParagraph,
  faHeading,
  faListOl,
  faListUl,
  faQuoteRight,
} from "@fortawesome/free-solid-svg-icons"
import createSoftNewLinePlugin from "@jimmycode/draft-js-soft-newline-plugin"

import Editor from "draft-js-plugins-editor"
import createLinkPlugin from "draft-js-anchor-plugin"
import createInlineToolbarPlugin from "draft-js-inline-toolbar-plugin"
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  CodeBlockButton,
} from "draft-js-buttons"

import { ComponentsContext } from "../../Contexts/ComponentsContext"
import { setTranslated } from "../../scripts/langUtils"
import { EditorContext } from "../../Contexts/EditorContext"

import "draft-js-inline-toolbar-plugin/lib/plugin.css"
import "draft-js-linkify-plugin/lib/plugin.css"

const linkPlugin = createLinkPlugin()

const inlineToolbarPlugin = createInlineToolbarPlugin({
  structure: [
    BoldButton,
    ItalicButton,
    UnderlineButton,
    CodeButton,
    HeadlineOneButton,
    HeadlineTwoButton,
    HeadlineThreeButton,
    UnorderedListButton,
    OrderedListButton,
    BlockquoteButton,
    CodeBlockButton,
    linkPlugin.LinkButton,
  ],
})

const { InlineToolbar } = inlineToolbarPlugin
const plugins = [inlineToolbarPlugin, linkPlugin]

export const EditableText = props => {
  const [state] = useContext(EditorContext)
  const [componentsState, componentsDispatch] = useContext(ComponentsContext)
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(stateFromMarkdown(props.text))
  )

  const onChange = editor => {
    console.log("MARKDOWN", stateToMarkdown(editor.getCurrentContent()))
    setEditorState(editor)
    componentsDispatch([
      "UPDATE_PROPS",
      {
        id: props.id,
        prop: {
          text: setTranslated(
            stateToMarkdown(editor.getCurrentContent()),
            props.rawText,
            state.lang
          ),
        },
      },
    ])
  }

  const customRenderMap = Map({
    unstyled: {
      element: "p",
      // will be used in convertFromHTMLtoContentBlocks
      aliasedElements: ["p"],
    },
  })

  const extendedBlockRenderMap = Draft.DefaultDraftBlockRenderMap.merge(
    customRenderMap
  )

  const handleReturn = event => {
    if (isSoftNewlineEvent(event)) {
      setEditorState(RichUtils.insertSoftNewline(editorState))
      return "handled"
    }
    return "not-handled"
  }

  let rule = css({
    marginTop: 0,
  })

  return (
    <div css={rule}>
      <Editor
        className="sb-text-editor"
        editorState={editorState}
        onChange={onChange}
        plugins={plugins}
        blockRenderMap={extendedBlockRenderMap}
        handleReturn={handleReturn}
      />
      <InlineToolbar>
        {
          // may be use React.Fragment instead of div to improve perfomance after React 16
          externalProps => (
            <div>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <linkPlugin.LinkButton {...externalProps} />
              <CodeButton {...externalProps} />
              <HeadlineOneButton {...externalProps} />
              <HeadlineTwoButton {...externalProps} />
              <HeadlineThreeButton {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
              <BlockquoteButton {...externalProps} />
              <CodeBlockButton {...externalProps} />
            </div>
          )
        }
      </InlineToolbar>
    </div>
  )
}
EditableText.displayName = "EditableText"
