import React from "react"
import { SVG, SVGCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileImage } from "@fortawesome/free-regular-svg-icons"

export default {
  id: "inline-svg",
  block: {
    category: "Basic",
    icon: <FontAwesomeIcon size="3x" icon={faFileImage} />,
    label: "Inline SVG",
  },
  props: [
    {
      name: "svg",
      label: "SVG",
      type: "code",
      default: {
        iv: `<svg height="100" width="100">
        <circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" />
    </svg>`,
      },
    },
  ],
  component: SVG,
  canvas: SVGCanvas,
  components: [],
  styles: {},
}
