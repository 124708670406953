import React from "react"

export const Image = ({ stateProps, htmlProps, children }) => {
  return <img src={stateProps.src} alt={stateProps.alt} {...htmlProps} />
}
Image.displayName = "Image"

export const ImageCanvas = ({ stateProps, htmlProps, children }) => {
  return <img src={stateProps.src} alt={stateProps.alt} {...htmlProps} />
}
ImageCanvas.displayName = "ImageCanvas"
