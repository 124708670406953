import React from "react"
import { SpaceComponent, SpaceCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRulerHorizontal } from "@fortawesome/free-solid-svg-icons"

export default {
  id: "antd-space",
  block: {
    category: "Ant Design",
    icon: <FontAwesomeIcon size="3x" icon={faRulerHorizontal} />,
    label: "Space",
  },
  props: [],
  component: SpaceComponent,
  canvas: SpaceCanvas,
  components: [],
  styles: {
    position: "relative",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
}
