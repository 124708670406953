import React from "react"
import { Text, TextCanvas } from "../Text/component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-regular-svg-icons"

export default {
  id: "richText",
  block: {
    category: "Basic",
    icon: <FontAwesomeIcon size="3x" icon={faEdit} />,
    label: "Rich Text",
  },
  props: [
    {
      name: "text",
      label: "Text",
      type: "text",
      default:
        "# This is a heading \n This is some **editable** text ++with++ _formatting_.",
      editable: false,
    },
  ],
  component: Text,
  canvas: TextCanvas,
  components: [],
  styles: {
    position: "relative",
  },
}
