import React from "react"
import { Text, TextCanvas } from "../Text/component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeading } from "@fortawesome/free-solid-svg-icons"

export default {
  id: "textHeader",
  block: {
    category: "Basic",
    icon: <FontAwesomeIcon size="3x" icon={faHeading} />,
    label: "Text Header",
  },
  props: [
    {
      name: "text",
      label: "Text",
      type: "text",
      default: "# This is a heading",
      editable: false,
    },
  ],
  component: Text,
  canvas: TextCanvas,
  components: [],
  styles: {
    position: "relative",
  },
}
