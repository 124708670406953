import React from "react"
import { ResetForm, ResetFormCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUndo } from "@fortawesome/free-solid-svg-icons"

export default {
  id: "from-reset",
  block: {
    category: "Form",
    icon: <FontAwesomeIcon size="3x" icon={faUndo} />,
    label: "Form Reset",
  },
  props: [],
  component: ResetForm,
  canvas: ResetFormCanvas,
  components: [],
  styles: {
    position: "relative",
  },
}
