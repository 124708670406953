import React from "react"
import { Space } from "antd"
import BlockSelector from "../../../templates/components/BlockSelector"

export const SpaceComponent = ({ stateProps, htmlProps, children }) => {
  return (
    <Space
      align={stateProps.align}
      direction={stateProps.direction}
      size={stateProps.size}
      {...htmlProps}
    >
      {children}
    </Space>
  )
}
SpaceComponent.displayName = "Space"

export const SpaceCanvas = ({ stateProps, htmlProps, children }) => {
  return (
    <Space
      align={stateProps.align}
      direction={stateProps.direction}
      size={stateProps.size}
      {...htmlProps}
    >
      {children}

      {!children && <BlockSelector selected={stateProps.id} />}
    </Space>
  )
}
SpaceCanvas.displayName = "SpaceCanvas"
