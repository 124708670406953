import React from "react"
import { Carousel } from "antd"

export const CarouselComponent = ({ stateProps, htmlProps }) => {
  const texts = stateProps.text.split("||").map(t => t.trim())

  return (
    <Carousel autoplay dots={false}>
      {texts.map((t, i) => (
        <div key={i} {...htmlProps}>
          {t}
        </div>
      ))}
    </Carousel>
  )
}

export const CarouselComponentCanvas = ({ stateProps, htmlProps }) => {
  const texts = stateProps.text.split("||").map(t => t.trim())

  return (
    <Carousel autoplay dots={false}>
      {texts.map((t, i) => (
        <div key={i} {...htmlProps}>
          {t}
        </div>
      ))}
    </Carousel>
  )
}
