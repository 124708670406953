import React from "react"
import { LanguageSwitch, LanguageSwitchCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLanguage } from "@fortawesome/free-solid-svg-icons"

export default {
  id: "language-switch",
  block: {
    category: "Basic",
    icon: <FontAwesomeIcon size="3x" icon={faLanguage} />,
    label: "Languages",
  },
  props: [],
  component: LanguageSwitch,
  canvas: LanguageSwitchCanvas,
  components: [],
  styles: {},
}
