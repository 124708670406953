import React from "react"
import BlockSelector from "../../templates/components/BlockSelector"
import { compareString } from "../../scripts/addPageContext"
import { css } from "glamor"
import { useContext } from "react"
import { PageContext } from "../../Contexts/PageContext"

function shouldShow(val1, val2, operator, state) {
  if (operator === "exists") return compareString(`!!${val1}`, state)
  else if (operator === "doesNotExists") return compareString(`!${val1}`, state)
  return compareString(`${val1} ${operator} ${val2}`, state)
}

export const ShowIf = ({ stateProps, htmlProps, children }) => {
  const [pageState, pageDispatch] = useContext(PageContext)

  // console.log("SHOW IF", pageState, stateProps)

  if (
    !shouldShow(stateProps.val1, stateProps.val2, stateProps.operator, {
      ...pageState,
      ...stateProps.customProps,
    })
  )
    return null

  return <div {...htmlProps}>{children}</div>
}
ShowIf.displayName = "ShowIf"

export const ShowIfCanvas = ({ stateProps, htmlProps, children }) => {
  const [pageState, pageDispatch] = useContext(PageContext)

  let style = css({
    border: shouldShow(stateProps.val1, stateProps.val2, stateProps.operator, {
      ...pageState,
      ...stateProps.customProps,
    })
      ? "1px dashed rgba(22, 171, 57, 0.4)"
      : "1px dashed rgba(0,0,0,0.2)",
    margin: "5px 0",
  })

  return (
    <div {...style} {...htmlProps}>
      {children}

      {!children && <BlockSelector selected={stateProps.id} />}
    </div>
  )
}
ShowIfCanvas.displayName = "ShowIfCanvas"
