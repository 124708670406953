import React from "react"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

export const SpinComponent = ({ htmlProps }) => {
  return <Spin indicator={antIcon} {...htmlProps} />
}

export const SpinComponentCanvas = props => {
  return <SpinComponent {...props} />
}
