import React from "react"
import { Repeatable, RepeatableCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons"

export default {
  id: "repeatable",
  block: {
    category: "Dynamic",
    icon: <FontAwesomeIcon size="3x" icon={faLayerGroup} />,
    label: "Repeatable",
  },
  props: [
    {
      name: "context",
      label: "Page Context Key",
      type: "context-reference",
      default: "",
    },
    {
      name: "ref",
      label: "Reference Name",
      type: "text",
      default: "item",
    },
  ],
  customProps: true,
  component: Repeatable,
  canvas: RepeatableCanvas,
  components: [],
  styles: {
    paddingTop: 20,
    paddingBottom: 20,
  },
}
