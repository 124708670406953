import React from "react"
import { Icon, IconCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faIcons } from "@fortawesome/free-solid-svg-icons"

import * as icons from "@fortawesome/free-regular-svg-icons"
import * as icons2 from "@fortawesome/free-solid-svg-icons"
console.log("ICONS", icons)

export default {
  id: "icon",
  block: {
    category: "Basic",
    icon: <FontAwesomeIcon size="3x" icon={faIcons} />,
    label: "Icon",
  },
  props: [
    {
      name: "icon",
      label: "Icon",
      type: "select",
      values: [...Object.values(icons2), ...Object.values(icons)].map(
        i => `${i.prefix} fa-${i.iconName}`
      ),
      default: "fas fa-check",
    },
  ],
  component: Icon,
  canvas: IconCanvas,
  components: [],
  styles: {},
}
