export const SCREEN_TYPES = {
  MOBILE: "mobile",
  TABLET: "tablet",
  DESKTOP: "desktop",
}

export const SCREEN_SIZE = {
  [SCREEN_TYPES.MOBILE]: `@media(max-width: 420px)`,
  [SCREEN_TYPES.TABLET]: `@media(max-width: 768px)`,
}

export const getStylesUtil = (styles, size) => {
  console.log("GET STYLES", styles, size)

  if (!styles) return {}

  if (size === SCREEN_TYPES.MOBILE)
    return styles[SCREEN_SIZE[SCREEN_TYPES.MOBILE]] || {}
  else if (size === SCREEN_TYPES.TABLET)
    return styles[SCREEN_SIZE[SCREEN_TYPES.TABLET]] || {}

  return {
    ...styles,
    [SCREEN_SIZE[SCREEN_TYPES.MOBILE]]: undefined,
    [SCREEN_SIZE[SCREEN_TYPES.TABLET]]: undefined,
  }
}

export const getAppliedStyles = (isCanvas, styles, size) => {
  if (!isCanvas) return styles
  if (!styles) return {}

  let output = [
    {
      ...styles,
      [SCREEN_SIZE[SCREEN_TYPES.MOBILE]]: undefined,
      [SCREEN_SIZE[SCREEN_TYPES.TABLET]]: undefined,
    },
  ]

  if (size === SCREEN_TYPES.TABLET || size === SCREEN_TYPES.MOBILE)
    output.push(styles[SCREEN_SIZE[SCREEN_TYPES.TABLET]] || {})
  if (size === SCREEN_TYPES.MOBILE)
    output.push(styles[SCREEN_SIZE[SCREEN_TYPES.MOBILE]] || {})

  return output
}

export const setStylesUtil = (styles, name, value, size) => {
  if (size === SCREEN_TYPES.MOBILE) {
    if (!styles[SCREEN_SIZE[SCREEN_TYPES.MOBILE]])
      styles[SCREEN_SIZE[SCREEN_TYPES.MOBILE]] = {}

    return {
      ...styles,
      [SCREEN_SIZE[SCREEN_TYPES.MOBILE]]: {
        ...styles[SCREEN_SIZE[SCREEN_TYPES.MOBILE]],
        [name]: value && value.length ? value : undefined,
      },
    }
  }

  if (size === SCREEN_TYPES.TABLET) {
    if (!styles[SCREEN_SIZE[SCREEN_TYPES.TABLET]])
      styles[SCREEN_SIZE[SCREEN_TYPES.TABLET]] = {}

    return {
      ...styles,
      [SCREEN_SIZE[SCREEN_TYPES.TABLET]]: {
        ...styles[SCREEN_SIZE[SCREEN_TYPES.TABLET]],
        [name]: value && value.length ? value : undefined,
      },
    }
  }

  return {
    ...styles,
    [name]: value && value.length ? value : undefined,
  }
}
