import React from "react"
import { ShowIf, ShowIfCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons"

export default {
  id: "show-if",
  block: {
    category: "Dynamic",
    icon: <FontAwesomeIcon size="3x" icon={faQuestionCircle} />,
    label: "Show If",
  },
  props: [
    {
      name: "val1",
      label: "Value 1",
      type: "page-context",
      default: "",
    },
    {
      name: "operator",
      label: "Operator",
      type: "select",
      default: "===",
      values: ["==", "!=", ">", "<", "exists", "doesNotExists"],
    },
    {
      name: "val2",
      label: "Value 2",
      type: "page-context",
      default: "",
    },
  ],
  component: ShowIf,
  canvas: ShowIfCanvas,
  components: [],
  styles: {
    position: "relative",
    paddingTop: 5,
    paddingBottom: 5,
  },
}
