import React, { useReducer, createContext, useEffect, useState } from "react"
import { sessionStore } from "../utils/customStorage"

export const PageContext = React.createContext()

function setFormValue(state, { formId, name, value, label, textValue }) {
  let newState = { ...state }
  if (!newState.forms) newState.forms = {}
  if (
    !newState.forms[formId] ||
    !newState.forms[formId].values ||
    !newState.forms[formId].detailed
  )
    newState.forms[formId] = { values: {}, detailed: {} }

  newState.forms[formId].values[name] = value
  newState.forms[formId].detailed[name] = { name, label, textValue, value }

  sessionStore.setItem("pb-forms", JSON.stringify(newState.forms))

  console.log("UPDATED FORM STATE", newState)

  return newState
}

function resetForm(state, formId) {
  let newState = { ...state }
  if (!newState.forms) newState.forms = {}

  newState.forms[formId] = {}

  sessionStore.setItem("pb-forms", JSON.stringify(newState.forms))

  console.log("RESET FORM STATE", formId, newState)

  return newState
}

const reducer = (state, [type, payload]) => {
  switch (type) {
    case "UPDATE":
      return {
        ...state,
        ...payload,
      }
    case "SET_FORM_VALUE":
      return setFormValue(state, payload)
    case "RESET_FORM":
      return resetForm(state, payload)
    default:
      return state
  }
}

export const PageContextProvider = props => {
  const [state, dispatch] = useReducer(
    reducer,
    props.initialState ? props.initialState : {}
  )
  const [oldState, setOldState] = useState(props.initialState)

  useEffect(() => {
    //console.log("INITIAL STATE UPDATED. WILL UPDATE?", state, props.initialState)
    if (JSON.stringify(oldState) !== JSON.stringify(props.initialState)) {
      console.log("UPDATE INITIAL STATE", props.initialState)
      dispatch(["UPDATE", props.initialState])
      setOldState(props.initialState)
    }

    if (!state.forms && sessionStore.getItem("pb-forms")) {
      dispatch([
        "UPDATE",
        {
          forms: JSON.parse(sessionStore.getItem("pb-forms")),
        },
      ])
    }
  })

  return (
    <PageContext.Provider value={[state, dispatch]}>
      {" "}
      {props.children}{" "}
    </PageContext.Provider>
  )
}
