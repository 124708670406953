import get from "lodash/get"

export const contextReference = (text, state) => {
  //console.log("TEXT", text)
  if (!text) return []

  if (!text.includes("(")) return get(state, text)

  var re1 = /(?<name>[a-zA-Z0-9_]+)\((?<params>(?:[a-zA-Z0-9\-".'[\]_]+,? ?)*)\)/gi

  const result = text.matchAll(re1)
  const results = Array.from(result)

  if (!results[0] || !results[0].groups) return []

  const groups = results[0].groups

  if (!groups) return []

  const args = groups.params.split(",").map(key => get(state, key.trim() || []))

  if (!state.functions) return null

  // console.log("ARGS", args)

  return state.functions[groups.name].apply(null, args)
}

export const functionReference = (text, state) => {
  //console.log("TEXT", text)
  if (typeof text === "undefined") return []

  if (!text.includes("(")) return get(state, text)

  var re1 = /(?<name>[a-zA-Z0-9_]+)\((?<params>(?:[a-zA-Z0-9\-".'[\]_]+,? ?)*)\)/gi

  const result = text.matchAll(re1)
  const results = Array.from(result)

  if (!results[0] || !results[0].groups) return []

  const groups = results[0].groups

  if (!groups) return []

  const args = groups.params.split(",").map(key => get(state, key.trim() || []))

  if (!state.functions) return null

  return () => state.functions[groups.name].apply(null, args)
}
