import React from "react"

export const Icon = ({ stateProps, htmlProps }) => {
  return (
    <i {...htmlProps} className={`${stateProps.icon} ${htmlProps.className}`} />
  )
}
Icon.displayName = "Icon"

export const IconCanvas = Icon
IconCanvas.displayName = "IconCanvas"
