import React, { useContext } from "react"
import { Collapse, Popover } from "antd"
import { CaretRightOutlined } from "@ant-design/icons"
import { useDrag } from "react-dnd"
import { css, jsx } from "@emotion/core"
import groupBy from "lodash/groupBy"

import { getComponents } from "../../components/index"
import { EditorContext } from "../../Contexts/EditorContext"
import { ComponentsContext } from "../../Contexts/ComponentsContext"

const { Panel } = Collapse

const Block = ({ block, addComponent, startDragging, endDragging }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { name: block.block.label, type: "block" },
    begin: () => {
      startDragging()
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        console.log(
          `Block ${block.id} was dropped on ${dropResult.id} at index ${dropResult.index}`
        )
        addComponent(
          block,
          dropResult.parentId,
          dropResult.id,
          dropResult.index
        )
        endDragging()
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0.4 : 1

  const style = css`
    opacity: ${opacity};
    cursor: move;
    height: 80px;
    width: 60px;
    text-align: center;
    font-size: 10px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  `

  return (
    <div ref={drag} css={style} className="pb-block-item pb-p-2">
      {block.block.icon} <br />
      {block.block.label}
    </div>
  )
}

const BlockSelector = props => {
  const [state, dispatch] = useContext(EditorContext)
  const [componentsState, componentsDispatch] = useContext(ComponentsContext)

  function addComponent(block, parentId, id, index) {
    componentsDispatch([
      "ADD_COMPONENT",
      {
        block,
        id,
        index,
        parentId,
      },
    ])

    dispatch(["SET_DRAGGING", null])
  }

  function startDragging() {
    dispatch(["SET_DRAGGING", "new-block"])
  }

  function endDragging() {
    dispatch(["SET_DRAGGING", null])
  }

  let groupedComponents = groupBy(getComponents, c => c.block.category)

  let containerStyle = css`
    width: 340px;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    column-gap: 0px;
    row-gap: 10px;
    justify-items: center;
    align-items: center;
    grid-auto-rows: 80px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
  `

  const content = (
    <Collapse
      bordered={false}
      accordion
      defaultActiveKey={["Basic"]}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      className="sb-selector-custom-collapse"
    >
      {Object.keys(groupedComponents).map(key => {
        return (
          <Panel header={key} key={key} className="sb-selector-custom-panel">
            <div css={containerStyle}>
              {groupedComponents[key].map((block, index) => (
                <Block
                  key={index}
                  block={block}
                  addComponent={addComponent}
                  startDragging={startDragging}
                  endDragging={endDragging}
                />
              ))}
            </div>
          </Panel>
        )
      })}
    </Collapse>
  )

  return (
    <Popover
      overlayClassName="sb-block-selector"
      placement="bottomLeft"
      title="Add a block"
      content={content}
    >
      {props.children}
    </Popover>
  )
}

export default BlockSelector
