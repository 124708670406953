import get from "lodash/get"
import { contextReference } from "./contextReference"

export const addPageContext = (text, state) => {
  if (typeof text === "undefined") return text

  if (text.includes("(")) return contextReference(text, state)

  var re1 = new RegExp(/\{[a-zA-Z0-9\-"\.'\[\]_]+\}/, "g")

  const result = text.replace(re1, match => {
    const re2 = new RegExp(/[\{|\}]/, "g")
    const key = match.replace(re2, "")
    return get(state, key)
  })

  if (re1.test(result)) return addPageContext(result, state)

  return result
}

function convert(valInput, state) {
  let val = addPageContext(valInput, state)

  if (val === "true") return true
  if (val === "false") return false
  if (val === "undefined" || typeof val === "undefined") return false

  return val
}

export const compare = (a, operator, b) => {
  switch (operator) {
    case "==":
      return a === b
    case "!=":
      return a !== b
    case "<":
      return a < b
    case ">":
      return a > b
    case "!!":
      return !!a
    case "!":
      return !a
    default:
      return false
  }
}

export const compareString = (string, state) => {
  if (!string) return false
  const vals = string.split(" ")

  if (vals[0][0] === "!") {
    return compare(convert(vals[0] && vals[0].replace("!", ""), state), "!")
  }

  if (vals[1] === undefined && vals[2] === undefined)
    return compare(convert(vals[0] && vals[0].replace("!!", ""), state), "!!")

  return compare(convert(vals[0], state), vals[1], convert(vals[2], state))
}
