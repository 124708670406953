/* eslint-disable no-case-declarations */
import React, { useReducer, useEffect } from "react"
import randomstring from "randomstring"
import { getPageOrSectionID } from "../scripts/server/pageContent"
import { localStore } from "../utils/customStorage"

export const ComponentsContext = React.createContext()

export const WrapperComponent = {
  type: "wrapper",
  parentId: null,
  props: {
    id: "wrapper",
  },
  components: [],
  classNames: [],
}

const initialState =
  localStore.getItem("sb-page-id") === getPageOrSectionID()
    ? JSON.parse(localStore.getItem("sb-components-state"))
    : {
        wrapper: WrapperComponent,
      }

function duplicateComponent(id, newId, parentId, state, newComponents) {
  let copy = JSON.parse(
    JSON.stringify({
      ...state[id],
      parentId: parentId,
    })
  )
  copy.props.id = newId

  newComponents[newId] = copy

  newComponents[newId].components = newComponents[newId].components.map(c => {
    let newId2 = randomstring.generate({
      length: 6,
      charset: "alphabetic",
    })

    duplicateComponent(c, newId2, newId, state, newComponents)

    return newId2
  })
}

function addComponent(state, block, id, parentId, index) {
  if (!parentId) parentId = state[id].parentId

  console.log("PARENT ID", id, parentId)

  let newItem = {
    type: block.id,
    parentId,
    props: {
      id: randomstring.generate({
        length: 6,
        charset: "alphabetic",
      }),
      ...block.props.reduce((obj, item) => {
        obj[item.name] = item.default
        return obj
      }, {}),
    },
    styles: block.styles || {},
    components: [],
    classNames: block.classNames || [],
  }

  state[parentId] = {
    ...state[parentId],
    components: [
      ...state[parentId].components.slice(0, index),
      newItem.props.id,
      ...state[parentId].components.slice(index),
    ],
  }

  return {
    ...state,
    [newItem.props.id]: newItem,
  }
}

function moveComponent(state, sourceId, targetId, targetParentId, index) {
  let oldParent = state[sourceId].parentId
  let newParent = targetParentId ? targetParentId : state[targetId].parentId

  console.log("OLD & NEW", oldParent, newParent)

  // Remove from old parent
  let newState = {
    ...state,
    [oldParent]: {
      ...state[oldParent],
      components: state[oldParent].components.filter(c => c !== sourceId),
    },
  }

  // Set new parent ID
  newState[sourceId] = {
    ...newState[sourceId],
    parentId: newParent,
  }

  // Add to new parent
  newState[newParent].components = [
    ...newState[newParent].components.slice(0, index),
    sourceId,
    ...newState[newParent].components.slice(index),
  ]

  console.log("NEW STATE", newState)

  return newState
}

const reducer = (state, [type, payload]) => {
  switch (type) {
    case "SET_INITIAL_STATE":
      return payload
    case "SET_COMPONENTS":
      return payload

    case "ADD_COMPONENT":
      console.log("ADD COMPONENT", payload)

      return addComponent(
        { ...state },
        payload.block,
        payload.id,
        payload.parentId,
        payload.index
      )
    case "UPDATE_STYLE":
      return {
        ...state,
        [payload.class]: {
          ...state[payload.class],
          styles: payload.styles,
        },
      }

    case "UPDATE_PROPS":
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          props: {
            ...state[payload.id].props,
            ...payload.prop,
          },
        },
      }
    case "UPDATE_ID":
      state[payload.newID] = {
        ...state[payload.id],
      }
      state[payload.newID].props.id = payload.newID

      for (let c of state[payload.newID].components) {
        state[c].parentId = payload.newID
      }

      const parentId = state[payload.newID].parentId
      state[parentId].components = state[parentId].components.map(compId =>
        compId === payload.id ? payload.newID : compId
      )

      delete state[payload.id]

      console.log("NEW ID STATE", state)

      return state

    case "DELETE_COMPONENT":
      let state2 = {
        ...state,
      }
      const parentId2 = state2[payload].parentId
      state2[parentId2].components = state2[parentId2].components.filter(
        compId => compId !== payload
      )

      delete state2[payload]

      console.log(payload, state2, state2[parentId2].components)

      return state2

    case "MOVE_COMPONENT":
      console.log("PAYLOAD UPDATE_COMPONENTS", payload)

      return moveComponent(
        { ...state },
        payload.sourceId,
        payload.targetId,
        payload.targetParentId,
        payload.index
      )

    case "DUPLICATE":
      const pId = state[payload].parentId
      const duplIndex = state[pId].components.findIndex(c => c === payload)
      const newComponents = {}

      let newId = randomstring.generate({
        length: 8,
        charset: "alphabetic",
      })

      duplicateComponent(payload, newId, pId, state, newComponents)

      console.log(
        "DUPLICATED COMPONENTS",
        state.selected,
        newId,
        pId,
        duplIndex,
        newComponents
      )

      return {
        ...state,
        ...newComponents,
        [pId]: {
          ...state[pId],
          components: [
            ...state[pId].components.slice(0, duplIndex + 1),
            newId,
            ...state[pId].components.slice(duplIndex + 1),
          ],
        },
      }

    case "ADD_CLASSNAME":
      let stateCopy = { ...state }

      stateCopy[payload.id].classNames = [
        ...stateCopy[payload.id].classNames,
        payload.value,
      ]

      return stateCopy

    case "DELETE_CLASSNAME":
      let state3 = { ...state }
      state3[payload.id].classNames = state3[payload.id].classNames.filter(
        c => c !== payload.value
      )

      return state3

    default:
    /* throw new Error("Undefined Action"); */
  }
}

export const ComponentsContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (
      JSON.stringify(state) !== JSON.stringify(initialState) &&
      JSON.stringify(state) !== localStore.getItem("sb-components-state")
    ) {
      if (localStore.getItem("sb-components-state"))
        localStore.setItem(
          "sb-page-last-modified",
          new Date(
            new Date() - new Date().getTimezoneOffset() * 60000
          ).toISOString()
        )
      localStore.setItem("sb-page-id", getPageOrSectionID())
      localStore.setItem("sb-components-state", JSON.stringify(state))
    }
  }, [state])

  return (
    <ComponentsContext.Provider value={[state, dispatch]}>
      {" "}
      {props.children}{" "}
    </ComponentsContext.Provider>
  )
}
