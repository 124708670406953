import React from "react"
import { Section, SectionCanvas } from "./component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faObjectGroup } from "@fortawesome/free-regular-svg-icons"

export default {
  id: "section",
  block: {
    category: "Layout",
    icon: <FontAwesomeIcon size="3x" icon={faObjectGroup} />,
    label: "Div Section",
  },
  props: [],
  component: Section,
  canvas: SectionCanvas,
  components: [],
  styles: {
    position: "relative",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
}
