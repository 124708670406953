import React, { useEffect } from "react"
import { EditOutlined } from "@ant-design/icons"

import { renderTopLevelComponent } from "../scripts/RenderReact"
import SEO from "../../../../src/components/seo"

import { Global, css } from '@emotion/core'

import "./page.scss"
import { PageContextProvider, PageContext } from "../Contexts/PageContext"
import { useContext } from "react"
import { SectionsContextProvider } from "../Contexts/SectionContext"
import { isAuthenticated } from "../utils/auth"
import { Button } from "antd"

const Page = (props) => {
    const pageContextArr = useContext(PageContext)
    // console.log("PAGE PROPS", props)

    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://kit.fontawesome.com/31f4337ba1.js";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);

    useEffect(() => {
        if (pageContextArr && pageContextArr[1]) {
            pageContextArr[1](['UPDATE', props.pageContext])
        }
    }, [props.pageContext.id])

    const wrapper = props.pageContext.page.type && props.pageContext.page.type.iv === "section" ? props.pageContext.page.slug.iv : 'wrapper'

    // console.log("WRAPPER", wrapper, props.pageContext.components)

    if (props.canvas) {
        return (
            <>
                <SEO title={`PB Editor | ${props.pageContext.name}`} />
                {renderTopLevelComponent(props.pageContext.components, wrapper, props.selected, true)}
            </>
        )
    }

    let lang = props.pageContext.page.data && props.pageContext.page.data.slug.iv ? props.pageContext.page.data.slug.iv.split("/")[0] : 'iv'
    
    // console.log("PAGE CONTEXT LANG", props.pageContext.lang)

    if (props.pageContext.lang === "fr" || props.pageContext.lang === "en") {
        lang = props.pageContext.lang
    }

    if (lang === "en") lang = "en-ca"
    else if (lang === "fr") lang = "fr-ca"

    // console.log("PAGE LANG", lang)

    let initialState = {
        id: props.pageContext.id,
        name: props.pageContext.name,
        ...props.pageContext,
        ...props.pageData,
        lang, 
    }

    let editStyle = css`
        position: fixed;
        bottom: 20px;
        right: 30px;
        z-index: 10000000;
    `

    return (
        <>
            <Global styles={props.pageContext.classes} />
            <SEO title={props.pageContext.name} />
            <PageContextProvider initialState={initialState}>
                <SectionsContextProvider initialState={props.pageContext.sections}>
                    {renderTopLevelComponent(props.pageContext.components, wrapper, null, false, props.pageContext)}
                </SectionsContextProvider>
            </PageContextProvider>
            {isAuthenticated() && <a href={`/admin/editor?page=${props.pageContext.id}`} target="_blank">
                <Button css={editStyle} type="primary" shape="round" icon={<EditOutlined />} />
            </a>}
        </>
    )
} 

export default Page
