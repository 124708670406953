import React from "react"
import classnames from "classnames"
import { css } from "glamor"
import { updateFormField, isSelected } from "../shared"
import { useContext } from "react"
import { PageContext } from "../../../Contexts/PageContext"

let fluidContainerStyle = css({
  "@media(min-width: 768px)": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
})

let fluidRadiosStyle = css({
  "@media(min-width: 768px)": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
    width: "70%",
    textAlign: "center",
  },
})

let fluidDisplayNone = css({
  "@media(min-width: 768px)": {
    display: "none",
  },
})

let fluidLabelStyle = css({
  "@media(min-width: 768px)": {
    width: "30%",
  },
})

let fluidRadioLabel = css({
  "@media(min-width: 768px)": {
    fontWeight: "400 !important",
    fontSize: 14,
    textAlign: "center",
  },
})

let fluidWidth = css({
  "@media(min-width: 768px)": {
    width: 80,
  },
})

let gridLayout = css({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
})

let inlineLayout = css({
  display: "flex",
  flexWrap: "wrap",
})

let inlineItem = css({
  marginRight: 20,
})

function generateRadios(
  name,
  values,
  labels,
  stateProps,
  htmlProps,
  state,
  dispatch,
  label
) {
  const valuesArr = values.split(",")
  const labelsArr = labels.split(",")

  return valuesArr.map((value, i) => {
    const update = e =>
      updateFormField(
        stateProps.form.id,
        name,
        e.target.value,
        dispatch,
        label,
        labelsArr[i].trim()
      )
    const isChecked = isSelected(stateProps.form.id, name, value.trim(), state)

    return (
      <div
        key={i}
        className={classnames(
          stateProps.fluid && fluidWidth.toString(),
          stateProps.inline && inlineItem.toString()
        )}
      >
        {!stateProps.labelsOnly && (
          <input
            type="radio"
            id={`${name.trim()}-${value}-${i}`}
            name={name.trim()}
            value={value.trim()}
            checked={isChecked}
            onChange={update}
          />
        )}
        {labelsArr[i] && (
          <label
            htmlFor={`${name.trim()}-${value}-${i}`}
            className={classnames(
              stateProps.fluid &&
                !stateProps.labelsOnly &&
                fluidDisplayNone.toString(),
              stateProps.labelsOnly && fluidRadioLabel.toString()
            )}
          >
            {labelsArr[i].trim()}
          </label>
        )}
      </div>
    )
  })
}

export const FormRadio = ({ stateProps, htmlProps, children }) => {
  const [state, dispatch] = useContext(PageContext)

  return (
    <div
      {...htmlProps}
      className={classnames(
        htmlProps.className,
        stateProps.fluid && fluidContainerStyle.toString(),
        "pb-field pb-field-group"
      )}
    >
      {!stateProps.hideLabel && (
        <label
          className={classnames(stateProps.fluid && fluidLabelStyle.toString())}
        >
          {stateProps.label}
        </label>
      )}
      <div
        className={classnames(
          stateProps.fluid && fluidRadiosStyle.toString(),
          stateProps.grid && gridLayout.toString(),
          stateProps.inline && inlineLayout.toString()
        )}
      >
        {generateRadios(
          stateProps.name,
          stateProps.values,
          stateProps.labels,
          stateProps,
          htmlProps,
          state,
          dispatch,
          stateProps.label
        )}
      </div>
    </div>
  )
}
FormRadio.displayName = "FormRadio"

export const FormRadioCanvas = FormRadio
FormRadioCanvas.displayName = "FormRadioCanvas"
