import query from "query-string"
import axios from "axios"
import { WrapperComponent } from "../../Contexts/EditorContext"

let styleDocID = ""

export const getPageOrSectionID = () => {
  const { page, section } = query.parse(
    typeof window === "undefined" ? "" : window.location.search
  )

  return page || section || null
}

export const getCanvasType = () => {
  const { page, section } = query.parse(
    typeof window === "undefined" ? "" : window.location.search
  )

  return (page && "page") || (section && "section") || null
}

export const getInitialContent = async () => {
  const id = getPageOrSectionID()

  const page = await axios.get(
    `https://cloud.squidex.io/api/content/lba-pages/${getCanvasType()}s/${id}`,
    {
      headers: {
        Authorization: `Bearer ${process.env.GATSBY_SQUIDEX}`,
      },
    }
  )
  console.log("INITIAL DATA", page)

  const styles = await axios.get(
    `https://cloud.squidex.io/api/content/lba-pages/styles`,
    {
      headers: {
        Authorization: `Bearer ${process.env.GATSBY_SQUIDEX}`,
      },
    }
  )
  console.log("INITIAL STYLE DATA", styles.data.items[0])
  styleDocID = styles.data.items[0].id

  return {
    components: page.data.data.components.iv,
    styles: page.data.data.styles.iv,
    classes: styles.data.items[0].data.classes.iv,
    page: page.data,
    pageLastModified: page.data.lastModified,
    stylesLastModified: styles.data.items[0].lastModified,
  }
}

export const getPages = async () => {
  const pages = await axios.get(
    `https://cloud.squidex.io/api/content/lba-pages/pages`,
    {
      headers: {
        Authorization: `Bearer ${process.env.GATSBY_SQUIDEX}`,
      },
    }
  )

  return pages.data.items
}

export const getSections = async () => {
  const sections = await axios.get(
    `https://cloud.squidex.io/api/content/lba-pages/sections`,
    {
      headers: {
        Authorization: `Bearer ${process.env.GATSBY_SQUIDEX}`,
      },
    }
  )

  return sections.data.items
}

export const saveContent = async (compoenntsState, stylesState) => {
  const id = getPageOrSectionID()

  const content = {
    components: {
      iv: compoenntsState,
    },
    styles: {
      iv: {},
    },
  }

  await axios.patch(
    `https://cloud.squidex.io/api/content/lba-pages/${getCanvasType()}s/${id}`,
    content,
    { headers: { Authorization: `Bearer ${process.env.GATSBY_SQUIDEX}` } }
  )

  await axios.patch(
    `https://cloud.squidex.io/api/content/lba-pages/styles/${styleDocID}`,
    { classes: { iv: stylesState.classes } },
    { headers: { Authorization: `Bearer ${process.env.GATSBY_SQUIDEX}` } }
  )

  await axios.post(
    `https://api.netlify.com/build_hooks/5f20efb2bf4bb733559e08e0`
  )
}

export const createContent = async (type, name, slug, components = null) => {
  let content = {
    name: {
      iv: name,
    },
    slug: {
      iv: slug,
    },
    components: {
      iv: components ? components : { wrapper: WrapperComponent },
    },
    styles: {
      iv: {},
    },
    draftComponents: {
      iv: {},
    },
    draftStyles: {
      iv: {},
    },
  }

  if (type === "section") {
    content.type = {
      iv: "section",
    }
  }

  return await axios.post(
    `https://cloud.squidex.io/api/content/lba-pages/${type}s?publish=true`,
    content,
    { headers: { Authorization: `Bearer ${process.env.GATSBY_SQUIDEX}` } }
  )
}
