import React from "react"
import { useContext } from "react"
import { EditorContext } from "../../../Contexts/EditorContext"
import BlockSelector from "../../../templates/components/BlockSelector"

export const ResetForm = ({ stateProps, htmlProps, children }) => {
  const [state, dispatch] = stateProps.pageContext

  console.log("RESET FORM ID", stateProps, stateProps.form)

  htmlProps.onClick = () => dispatch(["RESET_FORM", stateProps.form.id])

  return <div {...htmlProps}>{children}</div>
}
ResetForm.displayName = "ResetForm"

export const ResetFormCanvas = ({ stateProps, htmlProps, children }) => {
  const [state, dispatch] = useContext(EditorContext)

  return (
    <div {...htmlProps}>
      {children}
      {!children && <BlockSelector selected={stateProps.id} />}
    </div>
  )
}
ResetFormCanvas.displayName = "ResetFormCanvas"
