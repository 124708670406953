import React from "react"
import classnames from "classnames"
import { updateFormField, isChecked } from "../shared"

export const FormCheckbox = ({ stateProps, htmlProps, children }) => {
  const [state, dispatch] = stateProps.pageContext

  const checked = isChecked(stateProps.form.id, stateProps.name, state)
  const update = () =>
    updateFormField(
      stateProps.form.id,
      stateProps.name,
      !checked,
      dispatch,
      stateProps.label,
      !checked
    )

  return (
    <div {...htmlProps} className={classnames(htmlProps.className, "pb-field")}>
      <input
        type="checkbox"
        id={stateProps.name}
        name={stateProps.name}
        onChange={update}
        checked={checked}
      />
      {stateProps.label && (
        <label htmlFor={stateProps.name}>{stateProps.label}</label>
      )}
    </div>
  )
}
FormCheckbox.displayName = "FormCheckbox"

export const FormCheckboxCanvas = FormCheckbox
FormCheckboxCanvas.displayName = "FormCheckboxCanvas"
