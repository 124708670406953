import React, { useContext } from "react"
import markdownit from "markdown-it"
import underline from "markdown-it-plugin-underline"

import { EditorContext } from "../../Contexts/EditorContext"
import { EditableText } from "../../templates/components/EdtitableText"

const Renderer = markdownit({
  breaks: true,
}).use(underline)

export const Text = ({ stateProps, htmlProps }) => {
  return (
    <div {...htmlProps}>
      {stateProps.text && stateProps.text !== "undefined" ? (
        <div
          dangerouslySetInnerHTML={{ __html: Renderer.render(stateProps.text) }}
        />
      ) : null}
    </div>
  )
}
Text.displayName = "Text"

export const TextCanvas = ({ stateProps, htmlProps }) => {
  const [state] = useContext(EditorContext)

  if (state.selected !== stateProps.id)
    return <Text stateProps={stateProps} htmlProps={htmlProps} />

  console.log("TEXT CANVAS", stateProps)

  return (
    <div {...htmlProps}>
      <EditableText
        id={stateProps.id}
        text={stateProps.text}
        rawText={stateProps.textRaw}
      />
    </div>
  )
}
TextCanvas.displayName = "TextCanvas"
